// Generated by Framer (8107e78)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import PWAConfig from "https://framerusercontent.com/modules/a5UWOrQg4vKzzzjx0DFc/lpsq6cBBuqKkxazeWDs8/PWA.js";
const PWAConfigFonts = getFonts(PWAConfig);

const serializationHash = "framer-8O5NZ"

const variantClassNames = {eG173C0Fk: "framer-v-10xuqzt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({about, alerta, corDeFundo, corDoTema, descri_o, height, id, nomeDoSeuAPP, width, ...props}) => { return {...props, A1cLBJVt4: alerta ?? props.A1cLBJVt4, bQQIoM6vx: nomeDoSeuAPP ?? props.bQQIoM6vx ?? "Meu APP", EhMbmZBUC: about ?? props.EhMbmZBUC, fvZ16Cf5J: corDeFundo ?? props.fvZ16Cf5J ?? "rgb(255, 255, 255)", kLN6lsqPy: corDoTema ?? props.kLN6lsqPy ?? "rgb(58, 180, 159)", SMBOxNAGp: descri_o ?? props.SMBOxNAGp} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;alerta?: string;nomeDoSeuAPP?: string;descri_o?: string;corDeFundo?: string;corDoTema?: string;about?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, A1cLBJVt4, jKKMIdIXh, CZerNAnrF, bQQIoM6vx, SMBOxNAGp, OAd86Yyl8, fvZ16Cf5J, kLN6lsqPy, EhMbmZBUC, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "eG173C0Fk", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-10xuqzt", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"eG173C0Fk"} ref={refBinding} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p>!</motion.p></React.Fragment>} className={"framer-io9f89"} data-framer-name={"+"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"mHf4BhK8s"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", opacity: 0}} text={A1cLBJVt4} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p>!</motion.p></React.Fragment>} className={"framer-1ucy5qm"} data-framer-name={"+"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"iAwQ2kYAb"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", opacity: 0}} text={EhMbmZBUC} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-3jjunj-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"jYYn02_Au-container"} nodeId={"jYYn02_Au"} rendersWithMotion scopeId={"N4o77o6cB"}><PWAConfig backgroundColor={fvZ16Cf5J} description={SMBOxNAGp} display={OAd86Yyl8} height={"100%"} icon192={jKKMIdIXh} icon512={CZerNAnrF} id={"jYYn02_Au"} layoutId={"jYYn02_Au"} name={bQQIoM6vx} shortName={bQQIoM6vx} startUrl={"currentPage"} themeColor={kLN6lsqPy} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8O5NZ.framer-clh023, .framer-8O5NZ .framer-clh023 { display: block; }", ".framer-8O5NZ.framer-10xuqzt { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-8O5NZ .framer-io9f89, .framer-8O5NZ .framer-1ucy5qm { bottom: 0px; flex: none; left: -18px; position: absolute; right: 17px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; z-index: 1; }", ".framer-8O5NZ .framer-3jjunj-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8O5NZ.framer-10xuqzt { gap: 0px; } .framer-8O5NZ.framer-10xuqzt > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-8O5NZ.framer-10xuqzt > :first-child { margin-left: 0px; } .framer-8O5NZ.framer-10xuqzt > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 0
 * @framerIntrinsicWidth 0
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"A1cLBJVt4":"alerta","bQQIoM6vx":"nomeDoSeuAPP","SMBOxNAGp":"descri_o","fvZ16Cf5J":"corDeFundo","kLN6lsqPy":"corDoTema","EhMbmZBUC":"about"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerN4o77o6cB: React.ComponentType<Props> = withCSS(Component, css, "framer-8O5NZ") as typeof Component;
export default FramerN4o77o6cB;

FramerN4o77o6cB.displayName = "UncodePWA";

FramerN4o77o6cB.defaultProps = {height: 0, width: 0};

addPropertyControls(FramerN4o77o6cB, {A1cLBJVt4: {defaultValue: "", description: "Todos os campos devem ser preenchidos corretamente para que o manifesto seja gerado. ", displayTextArea: false, placeholder: "Leia abaixo:", title: "⚠️ Alerta:", type: ControlType.String}, bQQIoM6vx: {defaultValue: "Meu APP", description: "Informe o nome do seu app.", title: "Nome do seu APP", type: ControlType.String}, SMBOxNAGp: {defaultValue: "", description: "Fale um pouco sobre as funcionalidades do seu app.", placeholder: "O meu app é sobre...", title: "Descrição", type: ControlType.String}, fvZ16Cf5J: {defaultValue: "rgb(255, 255, 255)", description: "Cor de fundo do PWA em tela cheia.", title: "Cor de fundo", type: ControlType.Color}, kLN6lsqPy: {defaultValue: "rgb(58, 180, 159)", description: "Cor de fundo do PWA em tela cheia.", title: "Cor do Tema", type: ControlType.Color}, EhMbmZBUC: {defaultValue: "", description: "A página de Start do PWA é reconhecida automaticamente com base na página em que está inserido.\n\nEste componente foi desenvolvido por @omatusaelhorch.\n\nMais soluções em https://uncode.club", placeholder: "Sobre:", title: "About", type: ControlType.String}} as any)

addFonts(FramerN4o77o6cB, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PWAConfigFonts], {supportsExplicitInterCodegen: true})